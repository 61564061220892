import React from 'react';

const IconLogo = () => (
    <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <title>Logo</title>
      <g>
        <path
            id="Shape"
            stroke="currentColor"
            stroke-width="5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M 45.76,2.57
      C 45.76,2.57 88.26,26.32 88.26,26.32
        88.26,26.32 88.26,73.82 88.26,73.82
        88.26,73.82 45.76,97.57 45.76,97.57
        45.76,97.57 3.26,73.82 3.26,73.82
        3.26,73.82 3.26,26.32 3.26,26.32
        3.26,26.32 45.76,2.57 45.76,2.57 Z"
        />
        <g transform="translate(0.000000, 0.000000)">
          <path
              d="M 57.46,68.08
        C 57.46,68.08 53.37,56.68 53.37,56.68
          53.37,56.68 37.67,56.68 37.67,56.68
          37.67,56.68 33.64,68.08 33.64,68.08
          33.64,68.08 26.58,68.08 26.58,68.08
          26.58,68.08 41.94,26.87 41.94,26.87
          41.94,26.87 49.24,26.87 49.24,26.87
          49.24,26.87 64.60,68.08 64.60,68.08
          64.60,68.08 57.46,68.08 57.46,68.08M 51.59,50.90
        C 51.59,50.90 47.76,39.71 47.76,39.71
          47.47,38.98 47.08,37.80 46.58,36.19
          46.09,34.58 45.75,33.39 45.55,32.64
          45.06,34.94 44.32,37.46 43.35,40.19
          43.35,40.19 39.64,50.90 39.64,50.90
          39.64,50.90 51.59,50.90 51.59,50.90 Z"
              fill="currentColor"
          />
        </g>

      </g>
    </svg>
);

export default IconLogo;
